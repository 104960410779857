import { React, useState, useEffect } from "react";
import {
  Box,
  ImageList,
  Grid,
  ImageListItem,
  Typography,
  Button,
  ButtonGroup,
  TextField,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PenIcon from "@mui/icons-material/Edit";
import HotelDetailHeader from "../../../components/HotelDetailHeader";
import useIdFromPath from "../../../api/useIdFromPath";
import { useAuthHeader } from "react-auth-kit";
import { fetchData, patchData } from "../../../api/api";
import { tokens } from "../../../theme";

function HotelDetail() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const id = useIdFromPath();
  const [hotel, setHotel] = useState({});
  const authHeader = useAuthHeader();
  
  useEffect(() => {
    // Fetch data when component mounts
    const getHotel = async () => {
      try {
        const data = await fetchData(`hotel/notverified/${id}`, authHeader());
        setHotel(data);
        setFormData(data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    getHotel();
  });

  async function handleVerification() {
    const data = await patchData(`hotel/verify/${id}`, formData, authHeader());
    console.log(data)
  }

  function handleRejection() {

  }

  const [formData, setFormData] = useState({
    name: "",
    stars: 0,
    address: "",
    city: "",
    zipCode: "",
    country: "",
    email: "",
    phone: "",
    homepage: "",
    note: "",
    type: "",
    chargingStations: [],
    standingFee: false,
    thumbnail: "",
    images: [],
    verified: "Pending",
    location: { type: "Point", coordinates: [] },
    rejectionReason: "",
    verifiedBy: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({ ...formData, [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  // Handle change in charging stations
  const handleChargingStationsChange = (e, index) => {
    const { name, value } = e.target;
    const updatedStations = formData.chargingStations.map((station, i) => {
      if (i === index) {
        return { ...station, [name]: value };
      }
      return station;
    });
    console.log({ ...formData, [name]: value });
    setFormData({ ...formData, chargingStations: updatedStations });
  };

  return (
    <Box m="40px">
      <ButtonGroup sx={{ float: "right" }} size="medium">
        <Button
          sx={{ mr: "12px" }}
          variant="contained"
          color="secondary"
          onClick={handleVerification}
          startIcon={<PenIcon />}
        >
          Bearbeiten
        </Button>
        <Button
          sx={{ mr: "12px" }}
          variant="contained"
          color="error"
          startIcon={<CloseIcon />}
          onClick={handleRejection}
        >
          Ablehnen
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleVerification}
          startIcon={<CheckIcon />}
        >
          Verifizieren
        </Button>
      </ButtonGroup>
      
      <Box display="flex" justifyContent="space-between">
        <HotelDetailHeader title={hotel.name} ratingValue={hotel.stars} />
      </Box>
      <Box height="75vh">
        <Grid m="40px 0 0 0" container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" marginBottom={5}>
              TitelBild
            </Typography>
            <img src={hotel.thumbnail} width="75%" alt={hotel.name} />
            <Typography variant="h4" marginTop={5} marginBottom={5}>
              Weitere Bilder
            </Typography>
            <ImageList
              sx={{ width: "100%", height: "50vh" }}
              cols={3}
              rowHeight={164}
            >
              {hotel.images.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item}
                    loading="lazy"
                    key={item.id}
                  />
                </ImageListItem>
              ))}
              
            </ImageList>
          </Grid>
          <Grid item xs={12} md={6} backgroundColor={colors.primary[400]}>
            <form>
              <Grid container spacing={2}>
                {/* Example of a form field */}
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">Hotel Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    label="Hotel Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">Sterne</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="stars"
                    value={formData.stars}
                    onChange={handleChange}
                    label="Sterne"
                    type="number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">Strasse</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    label="Strasse"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">Stadt</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    label="Stadt"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">PLZ</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    label="PLZ"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">Telefon</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    label="Telefon"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">E-Mail</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    label="E-Mail"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h5">Homepage</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="homepage"
                    value={formData.homepage}
                    onChange={handleChange}
                    label="Homepage"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {formData.chargingStations.map((station, index) => [
                  <Grid
                    key={`label-${index}`}
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "40px" }}
                      src={station.chargerType.icon}
                      alt={station.chargerType.typeName}
                    ></img>
                    <Typography variant="h6">
                      {station.chargerType.typeName}
                    </Typography>
                  </Grid>,
                  <Grid key={`quantity-${index}`} item xs={8}>
                    <TextField
                      name="quantity"
                      value={station.quantity}
                      onChange={(e) => handleChargingStationsChange(e, index)}
                      label="Quantity"
                      type="number"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>,
                ])}

                {/* ... Add other fields similarly, each as a pair of Grid items ... */}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HotelDetail;
