import { useState, useEffect } from "react";
import { Box, useTheme, Avatar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {fetchData} from "../../api/api"
import { useAuthHeader } from 'react-auth-kit';

const Review = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reviews, setReviews] = useState([]);
  const authHeaderFunction = useAuthHeader();
  const [header, setHeader] = useState(authHeaderFunction());

  useEffect(() => {
    const getReviews = async () => {
      try {
        const data = await fetchData('review/notverified/', header);
        setReviews(data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };
    
    getReviews();
  }, [header]);  // Dependency on header

  useEffect(() => {
    setHeader(authHeaderFunction());  // Update header value whenever authHeaderFunction changes
  }, [authHeaderFunction]);  
  
  const columns = [
    {
      field: "profileImage",
      headerName: "Avatar",
      flex: 0,
      sortable: false,
      filterable: false,
      renderCell: (params) =><Avatar src={params.row.photoUrl} />
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="USER" subtitle="Alle User anzeigen" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={reviews} columns={columns}  getRowId={(row) => row._id} />
      </Box>
    </Box>
  );
};

export default Review;