import React, { useState, useContext } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  CssBaseline,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { postData } from "../../api/api";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import UserData from "../../api/userData";

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const signIn = useSignIn()
  const [error, setError] = useState(null);
  const {loadUserData } = useContext(UserData);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const openDetail = () => {
    navigate('/')
  };

  const handleSubmit = async (event) => {
    setError(null)
    event.preventDefault();
    setLoading(true);

    try {
        
      const response = await postData("auth/login", formData);
      console.log("Login success:", response);
      // Handle additional logic after successful login, if any

      if(signIn(
        {
            token: response.token,
            expiresIn: 3600,
            tokenType: "Bearer",
            authState: formData.email,
        }
    )){
        setError(null)
        loadUserData(response.token)
        openDetail()
    }else {
        //Throw error
    }

    } catch (error) {
        console.error("Login error:", error);

        // If error response exists and has a message, show that
        if (error.response && error.response.data.error) {
            setError(error.response.data.error);
        } else {
            // Otherwise, show a generic error message
            setError("An error occurred during login. Please try again.");
        }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper
        elevation={24}
        sx={{
          marginTop: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "secondary",
          padding: "48px",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            color="secondary"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color = "secondary"
            style={{ margin: "24px 0 16px" }}
            disabled={loading}
            endIcon={loading && <CircularProgress size={20} />}
          >
            Sign In
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default LoginPage;
