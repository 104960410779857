import {React, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {fetchData} from "../../api/api"
import { useAuthHeader } from 'react-auth-kit';
const Hotel = () => {
  const navigate = useNavigate();
  const [hotels, setHotels] = useState([]);
  const authHeaderFunction = useAuthHeader();
  const [header, setHeader] = useState(authHeaderFunction());

  useEffect(() => {
    const getHotels = async () => {
      try {
        const data = await fetchData('hotel/', header);
        setHotels(data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };
    
    getHotels();
  }, [header]);  // Dependency on header

  useEffect(() => {
    setHeader(authHeaderFunction());  // Update header value whenever authHeaderFunction changes
  }, [authHeaderFunction]);


  const openDetail = (
    params, // GridRowParams
  ) => {
    console.log(`Movie "${params.id}" clicked`);
    navigate('/data/hotel/detail/' + params.id)
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "name",
      headerName: "Hotel",
      flex: 1,
      editable: true,
      cellClassName: "name-column--cell",
    },
    {
      field: "typeName",
      headerName: "Typ",
      flex: 1,
      valueGetter: (params) => params.row.type.name
    },
    {
      field: "stars",
      headerName: "Anzahl Sterne",
      flex: 1,
    },
    {
      field: "city",
      headerName: "Stadt",
      flex: 1,
    },
    {
      field: "homepage",
      headerName: "Homepage",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="HOTEL" subtitle="Alle bereits verifizierten Hotels" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid onRowClick={openDetail} rows={hotels} columns={columns} getRowId={(row) => row._id}  />
      </Box>
    </Box>
  );
};

export default Hotel;