import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scene/global/Topbar";
import Sidebar from "./scene/global/Sidebar";
import Dashboard from "./scene/dashboard/index";
import Hotel from "./scene/data/Hotel";
import User from "./scene/data/User";
import HotelVerification from "./scene/verification/Hotel/Hotel";
import HotelVerificationDetail from "./scene/verification/Hotel/HotelDetail";
import ReviewVerification from "./scene/verification/Reviews";
import Team from "./scene/system/Team";
import Login from "./scene/login/Login";
import { RequireAuth } from "react-auth-kit";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation(); // Get the current location/path
  const isLoginPage = location.pathname === "/login"; // Check if current page is the login page

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        
          <div className="app">
            {!isLoginPage && <Sidebar isSidebar={isSidebar} />}{" "}
            {/* Conditionally render Sidebar */}
            <main className="content">
              {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}{" "}
              {/* Conditionally render Topbar */}
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      {" "}
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/data/hotel"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      <Hotel />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/data/user"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      <User />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/verification/hotel"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      <HotelVerification />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/verification/hotel/detail/:id"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      <HotelVerificationDetail />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/verification/reviews"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      <ReviewVerification />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/system/team"
                  element={
                    <RequireAuth loginPath={"/login"}>
                      <Team />
                    </RequireAuth>
                  }
                />
              </Routes>
            </main>
          </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
