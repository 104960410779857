import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const getDefaultHeaders = (token) => {
    if (token) {
        return {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        };
    }
    return {
        "Content-Type": "application/json",
    };
};

const apiCall = async (method, endpoint, data, token) => {
    const headers = getDefaultHeaders(token);

    try {
        const response = await axios({
            method,
            url: `${API_ENDPOINT}/${endpoint}`,
            data,
            headers,
            timeout: 30000,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// API Methods
export const fetchData = (endpoint, token) => apiCall("GET", endpoint, null, token);
export const postData = (endpoint, data, token) => apiCall("POST", endpoint, data, token);
export const postDataNoAuth = (endpoint, data) => apiCall("POST", endpoint, data);
export const patchData = (endpoint, data, token) => apiCall("PATCH", endpoint, data, token);
export const putData = (endpoint, data, token) => apiCall("PUT", endpoint, data, token);
export const delData = (endpoint, token) => apiCall("DELETE", endpoint, null, token);
