// GlobalStateProvider.js
import React, { useState, useCallback } from "react";
import UserData from "./userData";
import {fetchData} from "./api";
import { useAuthHeader } from "react-auth-kit";

const UserDataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const authHeader = useAuthHeader();
 
  const loadUserData = useCallback((token) => {
    const getUserData = async (token) => {
      try {
        const data = await fetchData(`user/profile`, token ? token : authHeader());
        setData(data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    getUserData(token);
  }, [authHeader]);

  return <UserData.Provider value={{ data, loadUserData }}>{children}</UserData.Provider>;
};

export default UserDataProvider;
