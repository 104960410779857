import { Typography, Box, useTheme, Rating} from "@mui/material";
import { tokens } from "../theme";

const HotelDetailHeader = ({ title, ratingValue }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant="h2"
        color={colors.gray[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Rating readOnly value={ratingValue}></Rating>
    </Box>
  );
};

export default HotelDetailHeader;